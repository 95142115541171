<template>
  <div>
    <div class="container-fluid my-4 d-print-none">
      <div class="row justify-content-around align-items-center">
        <div class="col-12 page-header flex justify-content-between align-items-center">
          <h1 class="page-title">Labels de Expedientes</h1>
          <a href="/" class="btn btn-outline-secondary btn-sm">Retornar a Licen</a>
        </div>
        <div class="col-12 col-md-3">
          <label>Cliente</label>
          <v-select
            v-model="filters.client"
            :options="clients_dropdown"
            id="client_id"
            :reduce="r => r.code"
            :placeholder="'Selecciona cliente'"
            @input="doFilter"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontraron resultados para <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>
                Empiece a escribir para buscar.
              </em>
            </template>
          </v-select>
        </div>
        <div class="col-12 col-md-3">
          <label>Fecha de admisión</label>
          <datetime v-model="filters.date" :config="dateConfig" id="date" class="form-control"></datetime>
        </div>
        <div class="col-12 col-md-2">
          <label for="from_record">Desde Expediente</label>
          <input v-model="filters.from_record" type="number" class="form-control" id="from_record">
        </div>
        <div class="col-12 col-md-2">
          <label for="to_record">Hasta Expediente</label>
          <input v-model="filters.to_record" type="number" class="form-control" id="to_record">
        </div>
        <div class="col-12 col-md-2">
          <label for="to_record">Instancia</label>
          <select v-model="filters.instance_id" name="instance_id" id="instance_id" class="form-control">
            <option selected>Seleccionar Instancia</option>
            <option value="1">Primera Instancia</option>
            <option value="2">Juzgado de Paz</option>
            <option value="3">Apelación</option>
            <option value="4">Casación</option>
          </select>
        </div>
        <div class="col-12 mt-4">
          <div class="row justify-content-end align-items-center">
            <div class="col-12 col-md-auto">
              <button class="btn btn-sm btn-info" type="button" @click.prevent="doFilter">Filtrar</button>
            </div>
            <div class="col-12 col-md-auto">
              <button class="btn btn-sm btn-outline-info" type="button" onclick="window.print();">Imprimir</button>
            </div>
            <div class="col-12 col-md-auto">
              <button class="btn btn-sm btn-outline-secondary" type="button" @click.prevent="resetFilter">Limpiar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border" style="width:100mm;height:150mm;" :class="{'page-break' : ((index+1) % 8) === 0 }" v-for="(record, index) in records" :key="record.id">
      <div class="card">
        <div class="card-body p-0">
        <div class="row">
          <div class="col-12 font-weight-bold h4 mb-2 mt-4 text-center text-truncate text-capitalize">
            <span v-if="record.client.type_id === 2">
              {{ record.client.commercial_name }}
            </span>
            <span v-else>
              {{ record.client.first_name }} {{ record.client.last_name  }}
            </span>
          </div>
          <div class="col-12 mb-0 text-center">
            <barcode
              :value="record.id"
              :options="{ flat: true, margin: 0, width: 3, height: 45, displayValue: false }"
              v-if="record.id"
            >
              Códgo inválido
            </barcode>
          </div>
          <div class="col-12 mb-2 font-weight-bold h5 mb-0 text-center text-truncate text-capitalize">
            {{ record.against_to | uppercase }}
          </div>
          <div class="col-12 mb-0 font-weight-bold h5 mb-2 text-center text-truncate text-capitalize">
            {{ record.name | uppercase }}
          </div>
          <div class="col-4 text-center font-weight-bold">
            A - {{ record.file_container }}
          </div>
          <div class="col-4 text-center font-weight-bold">
            F - {{ record.file_column }}
          </div>
          <div class="col-4 text-center font-weight-bold">
            C - {{ record.file_row }}
          </div>
        </div>
      </div>
      </div>
    </div>
    <div v-show="!records.length" class="col-12 text-center d-print-none">
      <h4>Sin información relevante.</h4>
    </div>
  </div>
</template>

<script>
import Barcode from '@chenfengyuan/vue-barcode'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RecordLabels',
  data () {
    return {
      dateConfig: {
        altInput: true,
        enableTime: false,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      },
      filters: {
        no_pagination: 'please',
        client: '',
        date: '',
        from_record: '',
        to_record: '',
        instance_id: ''
      }
    }
  },
  methods: {
    ...mapActions({
      getRecords: 'Record/getRecords',
      getClients: 'Client/getClients'
    }),
    ...mapMutations({
      setRecords: 'Record/SET_RECORDS'
    }),
    resetFilter () {
      this.filters.date = undefined
      this.filters.client = undefined
      this.filters.from_record = undefined
      this.filters.to_record = undefined
      this.filters.instance_id = undefined
      this.filters.no_pagination = 'please'
      this.setRecords([])
    },
    doFilter () {
      this.getRecords(this.filters)
    }
  },
  computed: {
    ...mapGetters({
      records: 'Record/records',
      clients_dropdown: 'Client/clients_dropdown'
    })
  },
  mounted () {
    this.getClients()
  },
  components: {
    Barcode
  }
}
</script>
